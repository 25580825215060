<template>
  <div>
    <v-card class="mr-2 mt-4 ml-4 mb-4 pb-0">
      <v-card-title class="text-h10">
        <h3>Tüketim</h3>
      </v-card-title>

      <v-card-text>
        <div class="d-flex">
          <v-col cols="6" sm="2" md="2" class="pr-3">
            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              :close-on-click="false"
              :return-value.sync="dates"
              transition="scale-transition"
              offset-y
              :rules="dateRules"
              :nudge-right="40"
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="dateRangeText"
                  :label="daterangetext"
                  :placeholder="all"
                  readonly
                  outlined
                  dense
                  v-on="on"
                  hide-details
                />
              </template>
              <v-date-picker
                ref="picker"
                v-model="dates"
                :selected-items-text="selecteditemstext"
                :locale="appLanguage"
                range
                :max="maxDate"
                :min="mindate"
              >
                <div class="">
                  <v-btn class="ml-2" small color="error" @click="cancelF">
                    {{ $t("button.b_cancel") }}
                  </v-btn>
                  <v-btn
                    class="ml-2"
                    small
                    color="success"
                    @click="save(dates)"
                  >
                    {{ $t("button.b_save") }}
                  </v-btn>
                </div>
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="2" class="pr-3">
            <v-select
              v-model="headersColumn"
              :items="headersList"
              :menu-props="{ maxHeight: '400' }"
              label="Kolon Seç:"
              multiple
              dense
              outlined
              hide-details
            >
              <template v-slot:selection="{ item, index }">
                <span
                  v-if="index === 0"
                  style="max-width: 120px"
                  class="d-inline-block text-truncate"
                  >{{ item.text }}</span
                >
                <span v-if="index === 1" class="grey--text text-caption"
                  >(+{{ headersColumn.length - 1 }})</span
                >
              </template>
            </v-select>
          </v-col>
          <v-col cols="3" sm="1" md="1" class="pr-3">
            <v-btn color="primary" @click="getCurrent"> Filtrele </v-btn>
          </v-col>
        </div>
      </v-card-text>
    </v-card>
    <div class="mr-2 mt-6 ml-4 mb-4 pb-0">
      <v-data-table
        dense
        :items-per-page.sync="itemsPerPage"
        :server-items-length="itemsLength"
        :footer-props="{
          'items-per-page-options': [5, 10, 20, 30, 40, 50, 100],
          showFirstLastPage: true,
          itemsPerPageText: 'Limit',
        }"
        :options.sync="options"
        :headers="headers"
        :page.sync="currentPage"
        :loading="loading"
        loading-text="Yükleniyor... Lütfen bekleyiniz"
        :items="desserts"
        class="elevation-1"
      />
    </div>
  </div>
</template>

<script>
import { getConsumption } from "@/api/Request/osos";
import moment from "moment";
import i18n from "@/locale";

export default {
  data: () => ({
    itemsPerPage: 10,
    itemsLength: 10,
    headers: [],
    menu: false,
    daterangetext: i18n.t("DateRange"),
    selecteditemstext: "",
    mindate: "2010",
    all: i18n.t("All"),
    dateRules: [(v) => !!v || "Date is required"],
    dates: [],
    desserts: [],
    search: "",
    checkbox: true,
    appLanguage: "tr",
    date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    modal: false,
    modal2: false,
    currentPage: 1,
    loading: false,
    options: {},
    params: {},

    exportDocumentOpen: {
      open: false,
      tableID: null,
    },
    startDate: moment().add(-10, "day").format("YYYY-MM-DD"),
    finishDate: moment().add(1, "day").format("YYYY-MM-DD"),
    headersColumn: [],
    headersList: [],
    ososSayac: [],
  }),
  computed: {
    computedStartDateFormatted() {
      return this.formatDate(this.startDate);
    },
    computedFinishDateFormatted() {
      return this.formatDate(this.finishDate);
    },
    dateRangeText() {
      const dateArr = this.dates;
      if (this.dates.length > 1) {
        if (dateArr[0] > dateArr[1]) {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.selecteditemstext = `${dateArr[1]} ` + "<p>" + `  ${dateArr[0]}`;
        } else {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.selecteditemstext = `${dateArr[0]} ` + "<p>" + `  ${dateArr[1]}`;
        }
      } else {
        // eslint-disable-next-line prefer-destructuring
        this.selecteditemstext = this.dates[0];
      }

      return `${dateArr[1]} / ${dateArr[0]}`;
    },
    computedDateFormatted() {
      return this.formatDate();
    },
    maxDate() {
      return new Date().toISOString().substr(0, 10);
    },
  },
  watch: {
    options: {
      handler() {
        this.loading = true;
        const { sortBy, sortDesc, page, itemsPerPage } = this.options;

        this.params.condiniton.skip = (page - 1) * itemsPerPage;
        this.params.condiniton.limit = itemsPerPage;

        this.getCurrent();
      },
    },
    headersColumn() {
      this.headers = [];
      this.headersList.forEach((item) => {
        if (this.headersColumn.indexOf(item.value) > -1) {
          this.headers.push(item);
        }
      });
    },
  },
  created() {
    this.selecteditemstext = `${this.startDate}/${this.finishDate}`;
    this.dates = [this.startDate, this.finishDate];
    console.log(this.selecteditemstext);
    this.params = {
      condiniton: {
        startDate: this.startDate,
        finishDate: this.finishDate,
        skip: 0,
        limit: this.itemsPerPage,
      },
    };
  },
  mounted() {},

  methods: {
    onDateSelected(daterange) {
      this.finishDate = moment(daterange[1]._d).format("YYYY-MM-DD");
      this.startDate = moment(daterange[0]._d).format("YYYY-MM-DD");
    },
    cancelF() {
      this.menu = false;
      this.daterangetext = i18n.t("DateRange");
      this.$refs.menu.save(this.dates);
    },
    save(date) {
      this.daterangetext = `${i18n.t("DateRange")} ${i18n.t(
        this.selectcolumn
      )}`;
      this.$refs.menu.save(date);
      this.loading = true;
      const selectdate = this.dates;

      if (date.length < 2) {
        this.finishDate = selectdate[0];
        this.startDate = selectdate[0];
      } else if (selectdate[0] > selectdate[1]) {
        this.finishDate = selectdate[0];
        this.startDate = selectdate[1];
      } else {
        this.finishDate = selectdate[1];
        this.startDate = selectdate[0];
      }
    },

    getCurrent() {
      this.params.condiniton.startDate = this.startDate;
      this.params.condiniton.finishDate = this.finishDate;

      getConsumption(this.params).then((r) => {
        console.log(r);

        this.itemsLength = r.data.summary.totalRecordsCount;
        this.desserts = r.data.data;
        this.loading = false;
        this.headersList = r.data.keys;

        this.headers = this.headersList;
        if (this.headersColumn.length === 0) {
          this.headersColumn = this.headersList.map((item) => item.value);
        }
      });
    },
  },
};
</script>
